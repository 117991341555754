import React, {Component} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import PropTypes from 'prop-types';

const CornerTriangle = styled.div`
  background-color: #edf1f4;
    display: inline-block;
    overflow: hidden;
    width: 200px;
    height: 200px;
    transform: rotate(45deg);
    position: relative;
    left: -100px;
    top: -100px;
    z-index: 2;
`;

const LogoLink = styled.a`
  display: inline-block;
max-width: 45px;
padding: .5rem;
margin: 0 auto;
transform: rotate(-45deg);
position: absolute;
top: 74px;
left: 123px;
`;

const LogoImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

const HeaderContainer = styled.div`
  display: inline-block;
  text-align: right;
  font-size: 1.5em;
`;

const Header1 = styled.h1`
  font-size: 7em;
  margin:0;
  color: #003A5D;
`;

const Header2 = styled.h2`
position: relative;
top: 24px;
font-size: 1.75em;
  color: #5c6670;
  font-weight: 600;
  margin:0;
`;

const Header3 = styled.h3`
  color: #CAC8C8;
  font-weight: normal;
  margin:0;
  position: relative;
  bottom: 20px;
  font-size: 1em;
`;

const ContentCenteringContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const ContentContainer = styled.div`
  max-width: 1240px;
  min-height: 650px;
  margin: 0 auto;
  text-align: center;
`;

const FooterCopyright = styled.footer`
    width: 100%;
    min-width: 1304px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding: 1.5em 0;
    text-align: center;
    float: left;
    background-color: #003a5d;
    height: 2em;
    z-index: -1;
      
    .content{
        width: 1240px;
        margin: 0 auto;
        padding: 0 1em;
        vertical-align: baseline;
    }

    .copyright {
        color: #ffffff;
        margin: .5em 0 0 0;
        a {
            text-decoration: none;
            cursor: pointer;
            margin-left: 1em;
            color: rgba(255, 255, 255, 0.75);
        }
        p {
            font-size: .8em;
            line-height: 1.2em;
        }
    }
`;

const LibraryListing = styled.ul`
  list-style: none;
  padding: 0;
  margin: 2rem 0 0;
`;

const LibraryListingItem = styled.li`
  position: relative;
  display: inline-block;

  & + & {
    margin-left: 2rem;
  }
`;

const StyledButton = styled.button`
  display: inline-block;
  color: #fff;
  background-color: #1E988A;
  text-decoration: none;
  border: none;
  width: auto;
    min-width: 190px;
    border-radius: 30px;
    padding: 18px 32px;
    box-sizing: border-box;
    letter-spacing: .5px;
    box-shadow: 0px 0px 0px 0px transparent;
    transition: box-shadow 100ms linear,background 100ms linear,transform 100ms linear;
    border: 0;
    outline: 0;
    transform: translateY(0) translateX(0);
    text-shadow: 1px 1px 5px rgba(0,0,0,.32);
    cursor: pointer;
    
    &:hover {
        box-shadow: 1px 1px 8px 0px #000;
        padding: 17px 31px;
        border: 1px solid #1e988a;
        transform: translateY(-2px) translateX(-2px);
    }
`;

const VersionListContainer = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  padding: 12px 12px 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
`;

const VersionList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.5);
  max-height: 240px;
  overflow-y: auto;
`;

const StyledVersionLink = styled.a`
  display: block;
  padding: 20px 12px;
  text-decoration: none;
  color: #5c6670;
  
  &:hover {
    text-decoration: underline;
  }
  
  & + & {
    border-top: 1px solid #CAC8C8;
  }
`;

const VersionLink = ({handleBlur, handleFocusIn, name, path, version}) => {
    return (
        <StyledVersionLink onBlur={handleBlur} onFocus={handleFocusIn} rel="nofollow" as={'a'} href={`/storybooks/${path}/${version}`}>{name} - {version}</StyledVersionLink>
    );
};

VersionLink.propTypes = {
    handleBlur: PropTypes.func.isRequired,
    handleFocusIn: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
};

class PackageSelector extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleFocusIn = this.handleFocusIn.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);

        this.state = {
            showVersions: false,
        };
    }

    showList() {
        this.setState({
            showVersions: true,
        });
    }

    hideList() {
        this.setState({
            showVersions: false,
        });
    }

    handleFocusIn() {
        this.showList();
    }

    handleBlur() {
        this.hideList();
    }

    handleMouseEnter() {
        this.showList();
    }

    handleMouseLeave() {
        this.hideList();
    }

    render() {
        const latestVersion = this.props.package.versions[0]; //The latest version should be the first element.
        const packageName = this.props.package.name;
        const path = this.props.package.path;
        let versions = [];

        for(let i=0; i<this.props.package.versions.length; i++) {
            const version = this.props.package.versions[i];

            versions.push(<VersionLink
                key={packageName + '-' + version}
                handleBlur={this.handleBlur}
                handleFocusIn={this.handleFocusIn}
                name={packageName}
                path={path}
                version={version}
            />);
        }

        return (
            <LibraryListingItem onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                <StyledButton onBlur={this.handleBlur} onFocus={this.handleFocusIn} as={'a'} rel="nofollow" href={`/storybooks/${path}/${latestVersion}`}>{packageName} - {latestVersion}</StyledButton>
                <VersionListContainer show={this.state.showVersions}>
                    <VersionList>{versions}</VersionList>
                </VersionListContainer>
            </LibraryListingItem>
        );
    }
}

const DisplayPackages = (packages) => {
    return (
        <LibraryListing>
            {packages.packages.map(packageItem => {
                return <PackageSelector key={packageItem.name} package={packageItem} />;
            })}
        </LibraryListing>
    );
};

class App extends Component {
    constructor(props) {
        super(props);

        this.handleDataFetchSuccess = this.handleDataFetchSuccess.bind(this);
        this.handleDataFetchFailue = this.handleDataFetchFailue.bind(this);

        this.state = {
            updatedOn: null,
            packages: [],
        }
    }

    componentDidMount() {
        axios.get("/assets/data/packages-info.json").then(this.handleDataFetchSuccess).catch(this.handleDataFetchFailure);
    }

    handleDataFetchSuccess(response) {
        this.setState({
            updatedOn: new Date(response.data.lastUpdated),
            packages: response.data.packages,
        });
    }

    handleDataFetchFailue(response) {
        alert("Failed to get data!");
    }

    render() {
        return (
            <div>
                <CornerTriangle>
                    <LogoLink rel="nofollow" href="/">
                        <LogoImg src="/assets/images/FullColorIcon.png" alt="RentVision Logo"/>
                    </LogoLink>
                </CornerTriangle>
                <ContentCenteringContainer>
                    <ContentContainer>
                        <HeaderContainer>
                            <Header2>RentVision Collection</Header2>
                            <Header1>Storybook</Header1>
                            <Header3>Updated on {this.state.updatedOn ? this.state.updatedOn.toDateString() : ''}</Header3>
                        </HeaderContainer>
                        <DisplayPackages packages={this.state.packages}/>
                    </ContentContainer>
                </ContentCenteringContainer>
                <FooterCopyright>
                    <div className="content">
                        <div className="copyright">
                            <p>Copyright &copy; 2014-{ new Date().getFullYear()} RentVision, LLC. All rights reserved.</p>
                        </div>
                    </div>
                </FooterCopyright>
            </div>
        );
    }
}

export default App;